import {
  Quality4khdrSvg,
  Quality4kuhdSvg,
  QualityDolby51Svg,
  QualityDolbyAtmosSvg,
  QualityDolbyVisionSvg,
  QualityHdSvg,
  QualityMonoSvg,
  QualitySdSvg,
  QualityStereoSvg,
} from '@dce-front/dive';
import { PlaysetQuality } from '@dce-front/onewebapp-utils';
import type { FC, JSX, SVGProps } from 'react';

type QualityIconsMapping = {
  [key: string]: FC<
    SVGProps<SVGSVGElement> & {
      title?: string;
      description?: string | undefined;
    }
  >;
};

const QUALITY_ICONS: QualityIconsMapping = {
  [PlaysetQuality.Sd]: QualitySdSvg,
  [PlaysetQuality.Hd]: QualityHdSvg,
  [PlaysetQuality.Uhd]: Quality4kuhdSvg,
  [PlaysetQuality.UhdHdr]: Quality4khdrSvg,
  [PlaysetQuality.UhdDbv]: QualityDolbyVisionSvg,
  [PlaysetQuality.Mono]: QualityMonoSvg,
  [PlaysetQuality.Stereo]: QualityStereoSvg,
  [PlaysetQuality.DolbyAtmos]: QualityDolbyAtmosSvg,
  [PlaysetQuality.Dolby_5_1]: QualityDolby51Svg,
  [PlaysetQuality.Dolby_5_1WithSpace]: QualityDolby51Svg,
};

/**
 * Quality icon using `DIVE` icons
 * @param quality     The audio/video **quality**
 * @returns           Returns an **Icon** from a quality given
 */
export function QualityIcon({
  quality,
}: {
  quality: PlaysetQuality;
}): JSX.Element | null {
  const Icon = QUALITY_ICONS[quality];
  if (!Icon) {
    return null;
  }
  return <Icon role="img" aria-label={quality} title={quality} />;
}
