import {
  getDefaultSeasonIndex,
  HorizontalList,
} from '@canalplus/mycanal-sharedcomponent';
import type { SeasonSelectorProps } from '@dce-front/dive';
import { SeasonSelector } from '@dce-front/dive';
import type { ApiV2ContextualOfferSelector } from '@dce-front/hodor-types/api/v2/contextual_offer_page/definitions';
import type { ApiV2Selector } from '@dce-front/hodor-types/api/v2/episodes/definitions';
import type { ApiV2MoreInfosSelector } from '@dce-front/hodor-types/api/v2/more_infos/definitions';
import { Binder, enter, memory, spatial } from '@dce-front/one-navigation';
import { DEFAULT_SEASON_INDEX } from '@dce-front/onewebapp-utils';
import classNames from 'classnames/bind';
import { useCallback, useMemo, type JSX, type ReactNode } from 'react';
import { useSelector } from 'react-redux';
import Linker from '../../../../../../components/Linker/Linker';
import {
  alignBinderToTop,
  scroll,
  scrollFocusedIntoHorizontalList,
} from '../../../../../../helpers/oneNavigation/scroll';
import { useTranslation } from '../../../../../../lang';
import {
  pathnameSelector,
  routerOnClickSelector,
  routingContextSelector,
} from '../../../../../../store/slices/routing-selectors';
import styles from './SeasonsTabsContainer.module.css';

const cx = classNames.bind(styles);

const SEASON_TYPE = 'season';

export type HodorSeason =
  | ApiV2Selector
  | ApiV2MoreInfosSelector
  | ApiV2ContextualOfferSelector;

export type SeasonsTabsContainerProps = {
  /** ID for SeasonsTabs  */
  itemId: string;

  /** seasons data from hodor */
  seasons?: HodorSeason[];

  /** custom className */
  className?: string;

  /** custom onClick */
  handleClick?: (onClick: HodorSeason['onClick']) => void;
} & Pick<SeasonSelectorProps<any>, 'data-testid'>;

/** Type parameter for {@link SeasonSelector | DIVE SeasonSelector} */
type SeasonSelectorItem = {
  id: number;
  label: string | undefined;
  description: string | undefined;
};

export function SeasonsTabsContainer({
  itemId,
  seasons,
  className,
  handleClick,
  'data-testid': dataTestId,
}: SeasonsTabsContainerProps): JSX.Element | null {
  const { t } = useTranslation();
  const tabParentOnClick = useSelector(routerOnClickSelector);
  const pathname = useSelector(pathnameSelector);
  const context = useSelector(routingContextSelector);
  const defaultHodorSeasonIndex = seasons?.findIndex(
    (season) => season.default,
  );

  // Generates a new one-navigation middleware each time the defaultHodorSeasonIndex or pathname changes
  const middleware = useMemo(() => {
    return [
      scroll([alignBinderToTop, scrollFocusedIntoHorizontalList()]),
      enter({
        forceFocusIndex: defaultHodorSeasonIndex || DEFAULT_SEASON_INDEX,
        shouldForceFocusOnce: true,
      }),
      memory(),
      spatial(),
    ];

    // Here we want to reset the middleware on each season change to reset memory, that's why we
    // use pathname in dependency array

    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultHodorSeasonIndex, seasons]);

  // Computes the default season index to focus
  const { activeSeasonIndex } = useMemo(
    () => getDefaultSeasonIndex(seasons || [], pathname || ''),
    [seasons, pathname],
  );

  const seasonItems = useMemo(
    () =>
      seasons?.map<SeasonSelectorItem>((season, index) => ({
        id: index,
        label: getSeasonLabel(season, t('Detail.season')),
        description: (season as ApiV2Selector)?.description,
      })) || [],
    [seasons, t],
  );

  const renderSeasonItemWrapper = useCallback<
    SeasonSelectorProps<SeasonSelectorItem>['seasonItemWrapper']
  >(
    ({ children }, index) => {
      const selectedSeason = seasons![index]!;
      const { seasonNumber, onClick } = selectedSeason;
      const ariaLabel =
        'ariaLabel' in selectedSeason ? selectedSeason['ariaLabel'] : undefined;

      const labelElement =
        selectedSeason.type === SEASON_TYPE && ariaLabel ? (
          <abbr title={ariaLabel}>{children}</abbr>
        ) : (
          children
        );

      const commonProps = {
        title: ariaLabel,
        'data-tv-focusmode': 'attribute',
        'data-navigateonfocus':
          $_BUILD_RENDERMODE_CSR && activeSeasonIndex !== index,
      };

      return handleClick ? (
        <button
          tabIndex={0}
          type="button"
          aria-label={ariaLabel}
          onClick={() => handleClick(onClick)}
          {...commonProps}
        >
          {labelElement}
        </button>
      ) : (
        <Linker
          data={{
            mainOnClick: {
              ...tabParentOnClick,
              path: onClick?.path,
            },
            context,
          }}
          ariaLabel={ariaLabel}
          replace
          {...commonProps}
        >
          {!$_BUILD_RENDERMODE_CSR &&
          pathname === onClick?.path &&
          seasonNumber ? (
            <h1>{labelElement}</h1>
          ) : (
            labelElement
          )}
        </Linker>
      );
    },
    [
      activeSeasonIndex,
      context,
      handleClick,
      pathname,
      seasons,
      tabParentOnClick,
    ],
  );

  const renderSeasonListWrapper = useCallback<
    NonNullable<SeasonSelectorProps<SeasonSelectorItem>['seasonListWrapper']>
  >(
    ({ children, className }) => {
      const seasonList = (
        <HorizontalList
          showControls={false}
          className={cx('SeasonsTabsContainer__seasonsList', className)}
        >
          {children as ReactNode[]}
        </HorizontalList>
      );
      return $_BUILD_RENDERMODE_CSR ? (
        <Binder
          middleware={middleware}
          className={cx('SeasonsTabsContainer__seasonsList__tvBinder')}
        >
          {seasonList}
        </Binder>
      ) : (
        seasonList
      );
    },
    [middleware],
  );

  const keyExtractor = useCallback<
    NonNullable<SeasonSelectorProps<SeasonSelectorItem>['keyExtractor']>
  >((_, seasonIndex) => `${itemId}-${seasonIndex}`, [itemId]);

  if (!seasons?.length) {
    return null;
  }

  return (
    <SeasonSelector
      className={cx('SeasonsTabsContainer', className)}
      currentSeasonIndex={activeSeasonIndex}
      data-testid={dataTestId}
      seasons={seasonItems}
      keyExtractor={keyExtractor}
      seasonItemDescription={getSeasonItemDescription}
      seasonItemLabel={getSeasonItemLabel}
      seasonItemWrapper={renderSeasonItemWrapper}
      seasonListWrapper={renderSeasonListWrapper}
    />
  );
}

/** Compute the season label */
const getSeasonItemLabel = (season: SeasonSelectorItem) => season?.label;

/** Compute the season description */
const getSeasonItemDescription = (season: SeasonSelectorItem) =>
  season?.description;

/**
 * Generates a label for a given season based on its properties and device context.
 *
 * @param season - The season object
 * @param seasonPrefix - A string used as a prefix for the season number, typically passed through i18n for localization.
 */
export const getSeasonLabel = (
  season: HodorSeason,
  seasonPrefix: string,
): string | undefined => {
  if ('label' in season && season.label) {
    return season.label;
  }

  if (season.seasonNumber !== undefined) {
    return $_BUILD_RENDERMODE_CSR
      ? `${seasonPrefix} ${season.seasonNumber}`
      : `S${season.seasonNumber}`;
  }

  return undefined;
};
