import { FunctionalPadlockSvg } from '@dce-front/dive';
import classNames from 'classnames';
import type { JSX } from 'react';
import styles from './TvodTitle.module.css';

type TvodTitleProps = {
  /** custom className */
  className?: string;

  /** custom className for subtitle */
  classNameSubtitle?: string;

  /** display string title */
  title: string;

  /** label with padlock icon */
  subtitle?: string;

  /** display icon beside subtile  */
  hasIcon?: boolean;
};

/**
 * TvodTitle Component
 *
 * This component is used to create titles in funnel tvod
 */
export function TvodTitle({
  className,
  title,
  subtitle,
  hasIcon = false,
  classNameSubtitle,
}: TvodTitleProps): JSX.Element {
  return (
    <div className={classNames(styles.tvodTitle, className)}>
      <div className={styles.tvodTitle__title}>{title}</div>
      {subtitle && (
        <div
          className={classNames(styles.tvodTitle__subtitle, classNameSubtitle)}
        >
          {hasIcon && (
            <FunctionalPadlockSvg
              className={styles[`tvodTitle__subtitle--icon`]}
              aria-hidden
            />
          )}
          <span
            className={classNames(styles[`tvodTitle__subtitle--label`], {
              [styles['tvodTitle__subtitle--with-icon']!]: hasIcon,
            })}
          >
            {subtitle}
          </span>
        </div>
      )}
    </div>
  );
}
