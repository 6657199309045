import type { ApiV2TvodPrice } from '@dce-front/hodor-types/api/v2/common/dto/tvod/defintions';
import classNames from 'classnames';
import type { JSX } from 'react';
import { TypePrice } from '../../../helpers/const';
import styles from './OfferPrice.module.css';

enum PriceClassName {
  InitialPrice = 'initialPrice',
  PromotionalPrice = 'promotionalPrice',
}

/**
 * Create component prices
 * @param initialPrice offer price
 * @param promotionalPrice promotional offer price
 * @param typePrice type major or minor
 * @param className custom className
 */
function displayPrices(
  initialPriceLabel?: string,
  promotionalPriceLabel?: string,
  typePrice?: TypePrice,
  className?: string,
  ariaLabel?: string,
): JSX.Element {
  function getOfferPriceClassNames(priceClassName: PriceClassName) {
    return classNames(
      styles.offerPrice,
      styles[`offerPrice__${priceClassName}`],
      {
        [styles[`offerPrice__${priceClassName}--major`]!]:
          typePrice === TypePrice.Major,
        [styles[`offerPrice__${priceClassName}--minor`]!]:
          typePrice === TypePrice.Minor,
        [styles[`offerPrice__${priceClassName}--strikethrough`]!]:
          promotionalPriceLabel &&
          priceClassName === PriceClassName.InitialPrice,
      },
    );
  }

  return (
    <div className={classNames(className)}>
      {promotionalPriceLabel ? (
        <s
          aria-hidden
          className={getOfferPriceClassNames(PriceClassName.InitialPrice)}
        >
          {initialPriceLabel}
        </s>
      ) : (
        <span className={getOfferPriceClassNames(PriceClassName.InitialPrice)}>
          {initialPriceLabel}
        </span>
      )}
      {promotionalPriceLabel && (
        <span
          aria-hidden
          className={getOfferPriceClassNames(PriceClassName.PromotionalPrice)}
        >
          {promotionalPriceLabel}
        </span>
      )}
      {ariaLabel && (
        <span className={styles.offerPrice__ariaLabel}>{ariaLabel}</span>
      )}
    </div>
  );
}

export type OfferPriceProps = {
  /** type price major or minor */
  typePrice?: TypePrice;

  /** custom className */
  className?: string;

  /** offer price with initial label and promotional label */
  prices: ApiV2TvodPrice;
  /** aria label hidden for prices */
  ariaLabel?: string;
};

/**
 * OfferPrice Component
 *
 * This component is used to create price in funnel tvod
 */
export function OfferPrice({
  className,
  typePrice,
  prices: { initialPriceLabel, promotionalPriceLabel },
  ariaLabel,
}: OfferPriceProps): JSX.Element {
  return (
    <>
      {displayPrices(
        initialPriceLabel,
        promotionalPriceLabel,
        typePrice,
        className,
        ariaLabel,
      )}
    </>
  );
}
