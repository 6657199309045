import { isSomeEnum } from '@dce-front/onewebapp-utils';

export enum PaymentMeanCode {
  IBAN = '01',
  CB1SHOT = 'CB1Shot',
  // recurrent CB is a CB saved by a subscriber to pay monthly his subscription (like iban but it's a CB)
  RECURRENT_CB = 'CB',
  // CB saved on TVOD paiement form during one shot payment, different than RECURRENT_CB_PAYMENT_CODE
  TVOD_CB = 'TV',
}

// Typeguard for PaymentMeanCode CB
export const isPaymentMeanCodeCB = (
  paymentMeanCode: string,
): paymentMeanCode is PaymentMeanCode => {
  return (
    paymentMeanCode === PaymentMeanCode.CB1SHOT ||
    paymentMeanCode === PaymentMeanCode.TVOD_CB
  );
};

/** @public */
export const isPaymentMeanCode = isSomeEnum(PaymentMeanCode);

// Used for old js files
export const RECURRENT_CB_PAYMENT_CODE = PaymentMeanCode.RECURRENT_CB;
export const IBAN_PAYMENTS = PaymentMeanCode.IBAN;
export const CB_TVOD_PAYMENTS = PaymentMeanCode.TVOD_CB;
export const CB1SHOT_PAYMENTS = PaymentMeanCode.CB1SHOT;
