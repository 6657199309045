import {
  MoralityDrugsNSvg,
  MoralityProfanityWSvg,
  MoralitySexSSvg,
  MoralityViolencePSvg,
} from '@dce-front/dive';
import type { ComponentType, JSX } from 'react';
import styles from './AdvisoriesIcons.module.css';

const advisories = ['N', 'P', 'S', 'W'] as const;

const advisoriesMapping = {
  // poland
  N: MoralityDrugsNSvg,
  P: MoralityViolencePSvg,
  S: MoralitySexSSvg,
  W: MoralityProfanityWSvg,
} satisfies Record<(typeof advisories)[number], ComponentType>;

export type AdvisoriesIconsProps = {
  advisoriesRatings: string[];
  className?: string;
};

/**
 * Component to display polish advisories icons from a list of advisories ratings.\
 * `N: Drugs, P: Violence, S: Sex, W: Profanity`
 *
 * @param props.advisoriesRatings  The list of advisories ratings
 * @param props.className          The class name to apply to the component
 * @returns Advisories icons SVGs or null if no advisories ratings
 */
export function AdvisoriesIcons({
  advisoriesRatings,
  className,
}: AdvisoriesIconsProps): JSX.Element | null {
  if (!advisoriesRatings?.length) {
    return null;
  }

  return (
    <span className={styles.AdvisoriesIcons__wrapper}>
      {advisoriesRatings.map((advisory) => {
        if (!advisories.includes(advisory as (typeof advisories)[number])) {
          return null;
        }

        const AdvisoryIcon =
          advisoriesMapping[advisory as (typeof advisories)[number]];

        return !AdvisoryIcon ? null : (
          <AdvisoryIcon
            title={`ParentalRating-advisory-${advisory}`}
            className={className}
            key={`advisory-${advisory}`}
          />
        );
      })}
    </span>
  );
}
