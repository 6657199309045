import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { deleteCreditCard } from '../../../../../../api/CreditCardApi/CreditCardApi';
import { useAppDispatch } from '../../../../../../helpers/hooks/useAppDispatch';
import { FetchRequestTypes } from '../../../../../../services/types';

type UseDeleteCreditCardParams = {
  /** Credit card id */
  contractId: string;
};

/**
 * Custom hook for deleting a credit card associated with a specific contractId.
 *
 * @returns {Function} function that takes a `DeleteCreditCardParams` object
 * with a `contractId` property to identify the credit card to be deleted.
 *
 */
export const useDeleteCreditCard = (): (({
  contractId,
}: UseDeleteCreditCardParams) => Promise<void>) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useCallback(
    async ({ contractId }: UseDeleteCreditCardParams) => {
      try {
        await dispatch(deleteCreditCard(contractId));
        await queryClient.refetchQueries({
          queryKey: [FetchRequestTypes.PaymentMeans],
        });
      } catch (e) {
        throw new Error(
          `[TVOD-URBA][useDeleteCreditCard] Error in delete credit card ${e}`,
        );
      }
    },
    [dispatch, queryClient],
  );
};
