import type { ApiV2TvodPaymentOnClick } from '@dce-front/hodor-types/api/v2/tvod/payment/definitions';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppHistory } from '../../../../../../helpers/hooks/reactRouter';
import { isImmersiveSelector } from '../../../../../../store/slices/immersive-selectors';
import { setIsOpen } from '../../../../../../templates/FunnelTvod/stores/funnel/actions';
import { useFunnelDispatch } from '../../../../../../templates/FunnelTvod/stores/funnel/hooks';

/**
 * Custom hook to close a tvod funnel modal and redirect users.
 *
 * @param redirectContent - ApiV2TvodPaymentOnClick object with redirection info.
 * @returns close modal when CSR and redirect user based on immersive state and redirection data.
 */
export const useHandleFunnelModalClose = (
  redirectContent?: ApiV2TvodPaymentOnClick,
): (() => void) => {
  const history = useAppHistory();
  const funnelDispatch = useFunnelDispatch();
  const isImmersive = useSelector(isImmersiveSelector);

  return useCallback(() => {
    if (redirectContent && redirectContent.path) {
      funnelDispatch(setIsOpen(false));
      if (isImmersive) {
        history.replace(redirectContent?.path, {
          ...history.location?.state,
          immersive: {
            ...history?.location?.state?.immersive,
            mainOnClick: redirectContent,
          },
        });
      } else {
        history.push(redirectContent.path, {
          ...history.location?.state,
          page: {
            ...history?.location?.state?.page,
            mainOnClick: redirectContent,
          },
        });
      }
    }
  }, [redirectContent, funnelDispatch, history, isImmersive]);
};
