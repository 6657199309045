import type { TurboPlatform } from '@canalplus/sdk-core';
import type { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type {
  ApiV2PaymentMeansDetail,
  ApiV2PaymentMeansPurchase,
} from '@dce-front/hodor-types/api/v2/tvod/payment_means/definitions';
import type { FetchOptions } from '@dce-front/util-fetch';
import { isTim } from '../../helpers/application/application-helper';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { fetch } from '../../helpers/fetch';
import Logger from '../../helpers/logger/logger-helper';
import {
  getPaymentProviderUrl,
  getRedirectPaymentUrl,
} from '../../helpers/url/url-helper';
import {
  type FunnelAction,
  setErrorTemplate,
} from '../../templates/FunnelTvod/stores/funnel/actions';
import type { PaymentMean } from '../../templates/FunnelTvod/stores/types';
import type { IPaymentMeansTurbo } from '../PaymentMeansApi/PaymentMeansApi.type';
import { apiCallWithTokenpassRenewal } from '../PersoApi/PersoApi';

export interface IPaySaleOrderRes {
  additionalMessage?: string;
  data: { actOfManagementId: number; purchaseId: number } | IPaymentMeansTurbo;
  actOfManagementId: number;
  purchaseId: number;
  message: string;
  returnCode: string;
  severity: string;
}

type ICreditCardPayment = {
  creditCardPayment: {
    orderId?: string;
    persistentCreditCard?: boolean;
    number?: string;
    expirationDate?: string;
    cryptogram?: string;
    owner?: string;
    creditCartType?: string;
  };
};

type IContractPayment = {
  contractPayment: { contractId: number; contractPaymentMeanId: number };
};

type IPurchasePayment =
  | IContractPayment
  | ICreditCardPayment
  | IPaymentMeansTurbo
  | null;

export type PaySaleOrderOptions = {
  providerId: string;
  purchaseId: string;
  purchasePayment?: IPurchasePayment;
};

interface IPaySaleOrderParams {
  data: PaySaleOrderOptions | Partial<IPaymentMeansTurbo>;
  purchaseId?: string;
  validationToken?: string;
  turboMedia?: TurboPlatform;
}

/**
 * paySaleOrder
 *
 * Pay the previously created order (TVOD)
 *
 * @param {string} passToken  user's pass token
 * @param {object} data       Data that will be stringified and put in the body
 * @returns {json|error}
 */
export const paySaleOrder =
  ({
    data,
    purchaseId = '',
    validationToken,
    turboMedia,
  }: IPaySaleOrderParams): Redux.ThunkAction<
    Promise<IPaySaleOrderRes | null>
  > =>
  async (dispatch) => {
    try {
      if (!data) {
        throw new Error('Arguments missing');
      }
      const body = isTim()
        ? JSON.stringify({
            paymentMean: data,
            pinJwToken: validationToken,
          })
        : JSON.stringify(data);

      const apiCall = async (options: FetchOptions) => {
        const url = getPaymentProviderUrl({ turboMedia, purchaseId });

        const newOptions = {
          body,
          headers: {
            ...options.headers,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          timeout: getPublicConfig().api.paymentProvider.timeout.processPayment,
        };

        const tokenPass =
          options?.headers && 'tokenPass' in options.headers
            ? options.headers.tokenPass
            : undefined;

        if (tokenPass) {
          Object.assign(
            newOptions.headers,
            isTim() ? { 'X-passtoken': tokenPass } : { passtoken: tokenPass },
          );
        }

        // We delete unauthorized headers to avoid potential cors errors

        if ('tokenPass' in newOptions.headers) {
          delete newOptions.headers.tokenPass;
        }

        if ('xx-profile-id' in newOptions.headers) {
          delete newOptions.headers['xx-profile-id'];
        }

        return fetch(url, newOptions);
      };

      const response = await dispatch(apiCallWithTokenpassRenewal(apiCall));

      if (!response) {
        throw new Error('SaleApi::paySaleOrder failed to fetch');
      }

      return response.json();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : error;

      Logger.error(`Error occurs during payOrder: ${errorMessage}`);

      return null;
    }
  };

/**
 * Payload TIM use for TURBO API
 * This payload allow redirectPayment to call initPayment API
 *
 * @param paymentMean selected payment mean
 */
const turboRedirectPaymentPayload = (paymentMean: PaymentMean) => {
  return {
    deviceSecurityLevel: 0,
    paymentMean,
  };
};

type PayloadKissType = {
  authorization: {
    bankCustomerId?: string;
    amount?: number;
    recurrentCB: boolean;
    saveOption: boolean;
    contractId: number;
  };
  distributorId: string;
  saleDevice: string;
  analyticsId?: string;
  trackingKey?: string;
  deviceId?: string;
};

/**
 * Payload FR use for KISS API
 * This payload allow redirectPayment to call initPayment API
 *
 * TODO: Temporary send for development too much data
 * @param detail contains prices of the content
 * @param paymentMean selected payment mean
 */
const kissRedirectPaymentPayload = (
  detail: ApiV2PaymentMeansDetail,
  paymentMean: PaymentMean,
  saveOption: boolean,
  userDeviceKeys: {
    trackingKey?: string;
    deviceId?: string;
    analyticsId?: string;
  },
): PayloadKissType => {
  const DEFAULT_CONTRACT_ID = 0; // Contract number for client KISS
  const publicConfig = getPublicConfig();
  return {
    authorization: {
      saveOption,
      amount: detail?.prices?.promotionalPrice || detail?.prices?.initialPrice,
      contractId: paymentMean?.contractId || DEFAULT_CONTRACT_ID,
      recurrentCB: false,
      bankCustomerId: paymentMean?.bankCustomerId,
    },
    distributorId: publicConfig.api.paymentProvider.distributor_id,
    saleDevice: publicConfig.api.paymentProvider.sale_device,
    ...userDeviceKeys,
  };
};

export type RedirectPaymentResponse = {
  redirectTo: ApiV2OnClick;
};

type RedirectPaymentApiParams = {
  purchase: ApiV2PaymentMeansPurchase;
  detail: ApiV2PaymentMeansDetail;
  paymentMean: PaymentMean;
  tokenCMS: string;
  saveOption: boolean;
  offerLocation: string;
  userDeviceKeys: {
    trackingKey?: string;
    deviceId?: string;
    analyticsId?: string;
  };
  funnelDispatch: React.Dispatch<FunnelAction>;
};

/**
 * Redirect payment return the callback of the call hodor
 * It contains the logical for payload TIM / FR
 * @param purchase object purchase with statement purchase and identifier
 * @param detail contains prices of the content
 * @param paymentMean selected payment mean
 */
export const redirectPaymentApi =
  ({
    purchase,
    detail,
    paymentMean,
    tokenCMS,
    saveOption,
    offerLocation,
    userDeviceKeys,
    funnelDispatch,
  }: RedirectPaymentApiParams) =>
  async (dispatch: Redux.Dispatch): Promise<RedirectPaymentResponse | null> => {
    try {
      const apiCall = async (options: FetchOptions) => {
        const mode = $_BUILD_RENDERMODE_CSR ? 'deported' : 'redirect';
        const url = getRedirectPaymentUrl({
          tokenCMS,
          mode,
          offerLocation,
          purchaseId: purchase.purchaseId,
        });

        const body = JSON.stringify(
          isTim()
            ? turboRedirectPaymentPayload(paymentMean)
            : kissRedirectPaymentPayload(
                detail,
                paymentMean,
                saveOption,
                userDeviceKeys,
              ),
        );

        const newOptions = {
          body,
          headers: {
            ...options.headers,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          timeout: getPublicConfig().api.paymentProvider.timeout.processPayment,
        };

        return fetch(url, newOptions);
      };

      const response = await dispatch(apiCallWithTokenpassRenewal(apiCall));

      if (!response) {
        funnelDispatch(
          setErrorTemplate({
            currentPage: { displayTemplate: 'paymentError' },
          }),
        );
        throw new Error('SaleApi::redirectPayment failed to fetch');
      }

      return response.json();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : error;

      Logger.error(`Error occurs during redirectPayment: ${errorMessage}`);

      return null;
    }
  };
