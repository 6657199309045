import { Button } from '@dce-front/dive';
import type { VoucherDetail as VoucherDetailType } from '@dce-front/hodor-types/modules/tvod/vouchers/definitions';
import classNames from 'classnames';
import type React from 'react';
import type { JSX } from 'react';
import styles from './VoucherDetail.module.css';

const buttonVoucherDeleteId = 'buttonVoucherDeleteId';

type VoucherDetailProps = {
  detail: VoucherDetailType;
  onDelete?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  // All data-attributes
  [key: `data-${string}`]: string;
};

export function VoucherDetail({
  detail,
  onDelete,
  ...rest
}: VoucherDetailProps): JSX.Element {
  const { title, description, availabilityLabel, action } = detail;
  const { onClick, label } = action || {};

  return (
    <div className={styles.voucherDetail}>
      <div
        className={classNames(styles.voucherDetail__content, {
          [styles['voucherDetail__content--fullWidth']!]: !action,
        })}
      >
        <div className={styles.voucherDetail__title}>{title}</div>
        <div className={styles.voucherDetail__description}>
          <span>{description}</span>
          <span>{availabilityLabel}</span>
        </div>
      </div>
      {action && (
        <Button
          id={buttonVoucherDeleteId}
          className={styles.voucherDetail__button}
          font="hind"
          variant={$_BUILD_RENDERMODE_CSR ? 'tertiary' : 'text'}
          onClick={onDelete}
          disabled={!onClick?.URLPage}
          {...rest}
        >
          {label}
        </Button>
      )}
    </div>
  );
}
