import { A11yAudioDescriptionSvg, A11ySmeSvg } from '@dce-front/dive';
import type { JSX } from 'react';

export type AccessibilityProps = {
  hasAudioDescription?: boolean;
  hasClosedCaptioning?: boolean;
  audioDescriptionText?: string;
  closedCaptioningText?: string;
};

/**
 * Accessibility component for displaying closed captioning and audio description.
 *
 * @param prop.hasAudioDescription       Boolean to display audio description
 * @param prop.hasClosedCaptioning       Boolean to display closed captioning
 * @param prop.audioDescriptionText   Text to display for audio description
 * @param prop.closedCaptioningText   Text to display for closed captioning
 * @returns Accessibility SVGs or null if no audioDescription nor closedCaptioning
 */
export function Accessibility({
  hasAudioDescription = false,
  hasClosedCaptioning = false,
  closedCaptioningText = 'Accessibility-closedCaptioning',
  audioDescriptionText = 'Accessibility-audioDescription',
}: AccessibilityProps): JSX.Element {
  return (
    <>
      {hasClosedCaptioning && (
        <A11ySmeSvg
          role="img"
          aria-label={closedCaptioningText}
          title={closedCaptioningText}
        />
      )}
      {hasAudioDescription && (
        <A11yAudioDescriptionSvg
          role="img"
          aria-label={audioDescriptionText}
          title={audioDescriptionText}
        />
      )}
    </>
  );
}
