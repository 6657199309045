import classNames from 'classnames';
import type { JSX } from 'react';
import { forwardRef, useId } from 'react';
import { useDiveContext } from '../../../context';
import {
  MaterialRadioCheckedSvg,
  MaterialRadioUncheckedSvg,
} from '../../../icons/icons';
import {
  radioActionableElementCVA,
  radioCheckIndicatorCVA,
  radioInputCVA,
  radioLabelContentCVA,
  radioLabelWrapperCVA,
  radioSubLabelCVA,
  radioWrapperCVA,
} from './Radio.cva';
import type { RadioProps } from './Radio.types';

/**
 * A Radio component allows users to select one option from a set.
 *
 * @example
 *
 * ```tsx
 * <Radio
 *    label="Option 1"
 *    name="option" value="1"
 *    checked={true}
 *    onChange={handleChange}
 *    disabled={false}
 *    isFormValidated={false}
 *    required={false}
 * />
 * ```
 */
export const Radio = forwardRef<HTMLInputElement, RadioProps>(function Radio(
  {
    id,
    label,
    subLabel,
    checked,
    disabled = false,
    actionableElement,
    isError,
    variant = 'horizontal',
    onChange,
    className,
    'data-testid': dataTestId,
    'data-tv-focusable': dataTvFocusable,
    'data-tv-focusmode': dataTvFocusmode,
    onClick,
    tabIndex,
    ...props
  },
  forwardedRef,
): JSX.Element {
  const { isTv, device } = useDiveContext();
  const radioUniqueId = useId();
  const inputId = id || radioUniqueId;
  const hasCustomTvNavigation = isTv && !!dataTvFocusable;

  return (
    <div
      data-testid={dataTestId}
      className={radioWrapperCVA({ disabled, className })}
      {...(hasCustomTvNavigation && {
        'data-tv-focusable': !disabled && dataTvFocusable,
        ...(dataTvFocusmode && {
          'data-tv-focusmode': dataTvFocusmode,
        }),
        onClick: (event) => {
          // Only trigger onChange if the radio is not already checked && enabled
          if (!checked && !disabled) {
            onChange?.({
              target: { name: props.name, value: props.value, checked: true },
            } as React.ChangeEvent<HTMLInputElement>);
          }
          onClick?.(event as React.MouseEvent<HTMLInputElement, MouseEvent>);
        },
        // for storybook
        tabIndex: 0,
      })}
    >
      <div
        className={classNames(
          'relative self-start box-border',
          isTv
            ? 'min-w-40 size-40 mr-dt-spacing-300'
            : 'min-w-22 size-22 mr-dt-spacing-200',
        )}
      >
        <input
          {...props}
          ref={forwardedRef}
          id={inputId}
          className={radioInputCVA({ disabled, hasCustomTvNavigation })}
          onChange={onChange}
          disabled={disabled}
          checked={checked}
          aria-checked={checked}
          aria-disabled={disabled}
          type="radio"
          tabIndex={hasCustomTvNavigation ? -1 : tabIndex}
        />
        {!checked && (
          <MaterialRadioUncheckedSvg
            className={radioCheckIndicatorCVA({
              disabled,
              isError,
              checked,
            })}
            aria-hidden
          />
        )}
        {checked && (
          <MaterialRadioCheckedSvg
            className={radioCheckIndicatorCVA({
              disabled,
              isError,
              checked,
            })}
            aria-hidden
          />
        )}
      </div>
      <div className={radioLabelWrapperCVA({ variant, hasCustomTvNavigation })}>
        <label
          htmlFor={inputId}
          className={radioLabelContentCVA({ variant, disabled, device })}
        >
          {/* Label */}
          {label && <span className="mr-dt-spacing-100">{label}</span>}

          {/* SubLabel */}
          {subLabel && (
            <span className={radioSubLabelCVA({ variant, device })}>
              {subLabel}
            </span>
          )}
        </label>
        {!isTv && actionableElement && checked && (
          <div className={radioActionableElementCVA({ variant })}>
            {actionableElement}
          </div>
        )}
      </div>
    </div>
  );
});
