import { cva } from 'class-variance-authority';

export const seasonSelectorListCVA = cva('flex list-none p-dt-spacing-none', {
  variants: {
    device: {
      web: 'flex-wrap -m-dt-spacing-50',
      tv: 'm-dt-spacing-none',
    },
  },
});

export const seasonSelectorItemCVA = cva(
  'flex justify-center text-center no-underline border-none font-dt-font-family-system cursor-pointer',
  {
    variants: {
      device: {
        web: [
          'py-dt-spacing-100 px-dt-spacing-200 rounded-dt-radius-medium',
          'hover:bg-dt-theme-surface-season-selector-season-selector-selected',
          'font-dt-font-weight-semibold text-dt-font-size-18 leading-dt-font-line-height-22',
          'dt-breakpoint-md-start:text-dt-font-size-20 dt-breakpoint-md-start:leading-dt-font-line-height-24',
          'hover:text-dt-theme-text-season-selector-season-selector-selected',
        ],
        tv: [
          'pt-dt-spacing-250 pb-dt-spacing-225 px-dt-spacing-500',
          'font-dt-font-weight-regular text-dt-font-size-30 leading-dt-font-line-height-36',
          'tv-focus-self:rounded-dt-radius-xlarge tv-focus-self:outline-none tv-focus-self:scale-tv-focus',
          'tv-focus-self:bg-dt-theme-tv-background-season-selector-season-selector-focus',
          'tv-focus-self:text-dt-theme-tv-text-season-selector-season-selector-focus',
        ],
      },
      isSelected: {
        true: undefined,
      },
    },
    compoundVariants: [
      {
        device: 'web',
        isSelected: true,
        className: [
          'bg-dt-theme-surface-season-selector-season-selector-selected',
          'text-dt-theme-text-season-selector-season-selector-selected',
        ],
      },
      {
        device: 'web',
        isSelected: false,
        className: [
          'bg-dt-theme-surface-season-selector-season-selector-unselected',
          'text-dt-theme-text-season-selector-season-selector',
        ],
      },
      {
        device: 'tv',
        isSelected: true,
        className: [
          'rounded-dt-radius-xlarge',
          'text-dt-theme-tv-text-season-selector-season-selector-select',
          'bg-dt-theme-tv-background-season-selector-season-selector-selected',
        ],
      },
      {
        device: 'tv',
        isSelected: false,
        className: [
          'bg-transparent',
          'text-dt-theme-tv-text-season-selector-season-selector-default',
        ],
      },
    ],
  },
);
