import { isSomeEnum } from '../function/isSomeEnum';

export const PlaysetQuality = {
  Sd: 'SD',
  Hd: 'HD',
  Hdr: 'HDR',
  Uhd: 'UHD',
  UhdHdr: 'UHD-HDR',
  UhdDbv: 'UHD-DBV',
  Mono: 'MONO',
  Stereo: 'STEREO',
  Dolby_5_1: 'DOLBY_5.1',
  Dolby_5_1WithSpace: 'DOLBY 5.1',
  DolbyAtmos: 'DOLBY_ATMOS',
} as const;

export type PlaysetQuality =
  (typeof PlaysetQuality)[keyof typeof PlaysetQuality];

export const isPlaysetQuality = isSomeEnum(PlaysetQuality);
