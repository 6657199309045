import classNames from 'classnames';
import {
  cloneElement,
  type JSX,
  type ReactElement,
  type ReactNode,
} from 'react';
import { useDiveContext } from '../../context';
import {
  seasonSelectorItemCVA,
  seasonSelectorListCVA,
} from './SeasonSelector.cva';
import type { SeasonSelectorProps } from './SeasonSelector.types';

const defaultSeasonListWrapperRenderer = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}): ReactElement => <ul className={className}>{children}</ul>;

/**
 * The `SeasonSelector` component allows users to navigate and select a TV series season.
 * Users can seamlessly switch between seasons to view the episodes within each season.
 *
 * This component supports the following customizable methods:
 * - `seasonItemLabel`: Allows adding a custom label to each season element.
 * - `seasonItemDescription`: Enables providing a custom description for each season.
 * - `seasonItemWrapper`: Wraps each season element with a custom component or structure.
 * - `seasonListWrapper`: Wraps the entire list of seasons with a custom component.
 *
 * These options provide flexibility for tailoring the component to various use cases and designs.
 *
 *  @example
 *
 * ```tsx
 * <SeasonSelector
 *   currentSeasonIndex={0}
 *   keyExtractor={(season) => season.id}
 *   seasons={[
 *     { id: 1, label: 'Season 1', description: 'Season 1 description' },
 *     { id: 2, label: 'Season 2', description: 'Season 2 description' }
 *   ]}
 *   seasonItemLabel={(season) => season.label}
 *   seasonItemDescription={(season) => season.description}
 *   seasonItemWrapper={({ children }) => <button>{children}</button>}
 * />
 * ```
 */
export function SeasonSelector<T extends object>({
  seasons,
  seasonItemDescription,
  seasonItemLabel,
  seasonItemWrapper,
  seasonListWrapper = defaultSeasonListWrapperRenderer,
  currentSeasonIndex = 0,
  keyExtractor,
  className,
  'data-testid': dataTestId,
}: SeasonSelectorProps<T>): JSX.Element | undefined {
  const { isTv, device } = useDiveContext();

  const selectedSeason = seasons.find(
    (_, index) => currentSeasonIndex === index,
  );

  const description =
    selectedSeason &&
    seasonItemDescription &&
    seasonItemDescription(selectedSeason, currentSeasonIndex);

  if (!seasons?.length) {
    return undefined;
  }

  return (
    <div data-testid={dataTestId} className={className}>
      {seasonListWrapper({
        children: seasons.map((season, index) => {
          const label = seasonItemLabel(season, index);
          const seasonItemKey =
            keyExtractor?.(season, index) ||
            `season-selector-list-item-${label}`;

          return (
            <li
              key={seasonItemKey}
              className={classNames('h-full whitespace-nowrap', {
                'min-w-60 dt-breakpoint-md-start:min-w-68 m-dt-spacing-50':
                  !isTv,
              })}
            >
              {cloneElement(seasonItemWrapper({ children: label }, index), {
                className: classNames(
                  seasonSelectorItemCVA({
                    device,
                    isSelected: currentSeasonIndex === index,
                  }),
                ),
              })}
            </li>
          );
        }),
        className: seasonSelectorListCVA({ device }),
      })}
      {description && (
        <span
          className={classNames(
            'font-dt-font-family-system font-dt-font-weight-regular block content-end',
            isTv
              ? [
                  'h-28 text-dt-font-size-24 leading-dt-font-line-height-28',
                  'text-dt-theme-tv-text-season-selector-season-selector-description mt-dt-spacing-300',
                ]
              : [
                  'text-dt-font-size-14 leading-dt-font-line-height-18',
                  'dt-breakpoint-md-start:text-dt-font-size-16 dt-breakpoint-md-start:leading-dt-font-line-height-20',
                  'h-18 text-dt-theme-text-episode-list-episode-list-description mt-dt-spacing-100',
                ],
          )}
        >
          {description}
        </span>
      )}
    </div>
  );
}
