import { List } from '@dce-front/dive';
import type { ApiV2ContextualStrateOffer } from '@dce-front/hodor-types/api/v2/contextual_offer_page/definitions';
import type { JSX } from 'react';

export type MinorOfferProps = ApiV2ContextualStrateOffer & {
  /** custom className */
  className?: string;

  /** function triggered when user clicking on the component */
  onClick: () => void;

  /** whether we are on mobile or not */
  isMobile: boolean;
};

/** This component is used to display MinorOfferItem  */
export function MinorOffer({
  className,
  isMobile,
  onClick,
  prices,
  subtitle,
  title,
}: MinorOfferProps): JSX.Element {
  const { initialPriceLabel, promotionalPriceLabel } = prices || {};
  return (
    <List
      className={className}
      onClick={onClick}
      title={title || ''}
      subtitle={subtitle}
      lineThroughLabel={promotionalPriceLabel ? initialPriceLabel : undefined}
      label={promotionalPriceLabel || initialPriceLabel}
      layout={isMobile ? 'stacked' : 'inline'}
      displayChevron
    />
  );
}
