import { cva } from 'class-variance-authority';

export const radioWrapperCVA = cva(
  'flex items-center w-auto h-auto font-dt-font-family-system',
  {
    variants: { disabled: { true: 'opacity-dt-opacity-50', false: '' } },
  },
);

export const radioInputCVA = cva(
  'flex appearance-none size-full m-dt-spacing-none self-end focus-visible:outline-none',
  {
    variants: {
      disabled: { true: 'cursor-not-allowed', false: 'cursor-pointer' },
      hasCustomTvNavigation: { true: 'pointer-events-none', false: '' },
    },
  },
);

export const radioCheckIndicatorCVA = cva(
  [
    'pointer-events-none absolute size-full top-0 left-0',
    'fill-dt-theme-border-radio-button-radio-button',
  ],
  {
    variants: {
      disabled: { true: 'cursor-not-allowed', false: 'cursor-pointer' },
      isError: { true: '', false: '' },
      checked: { true: '', false: '' },
    },
    compoundVariants: [
      {
        checked: false,
        isError: true,
        className: 'fill-dt-theme-border-radio-button-radio-button-error',
      },
    ],
  },
);

export const radioLabelWrapperCVA = cva(
  'flex self-end items-start dt-breakpoint-xs:items-center w-full',
  {
    variants: {
      variant: {
        vertical: 'flex-col dt-breakpoint-xs:flex-row',
        horizontal: 'flex-row',
      },
      hasCustomTvNavigation: { true: 'pointer-events-none', false: '' },
    },
  },
);

export const radioLabelContentCVA = cva('flex select-none', {
  variants: {
    variant: { vertical: 'flex-col', horizontal: 'flex-row' },
    disabled: { true: 'cursor-not-allowed', false: 'cursor-pointer' },
    device: {
      web: [
        'font-dt-system-hind-label-label-3-regular',
        'text-dt-theme-text-radio-button-radio-label',
      ],
      tv: [
        'font-dt-system-hind-title-title-4-regular-tv',
        // TODO: migrate to Radio tokens in #13888
        'text-dt-theme-tv-text-list-list-row-label-default',
        'tv-focus-parent:text-dt-theme-tv-text-list-list-row-label-focus',
      ],
    },
  },
});

export const radioSubLabelCVA = cva('', {
  variants: {
    variant: { vertical: '', horizontal: '' },
    device: {
      web: [
        'font-dt-system-hind-label-label-3-regular',
        'text-dt-theme-text-radio-button-radio-sublabel',
      ],
      tv: [
        'font-dt-system-hind-body-body-1-regular-tv',
        // TODO: migrate to Radio tokens in #13888
        'text-dt-theme-tv-text-list-list-row-sublabel-default',
        'tv-focus-parent:text-dt-theme-tv-text-list-list-row-sublabel-focus',
      ],
    },
  },
  compoundVariants: [
    { variant: 'vertical', device: 'web', className: 'mt-dt-spacing-50' },
    { variant: 'vertical', device: 'tv', className: 'mt-dt-spacing-100' },
  ],
});

export const radioActionableElementCVA = cva('flex', {
  variants: {
    variant: {
      vertical: [
        'ml-dt-spacing-none dt-breakpoint-xs:ml-auto ',
        'mt-dt-spacing-200 dt-breakpoint-xs:mt-dt-spacing-none dt-breakpoint-xs:pl-dt-spacing-200',
      ],
      horizontal: 'ml-auto',
    },
  },
});
