/**
 * Function Helper to Remove Properties from Object
 *
 * @param keys keys of object properties to remove
 * @param object object with properties to remove
 *
 */
export const removePropertiesFromObject = (
  keys: Array<string>,
  object: Record<string, unknown>,
): Record<string, unknown> => {
  keys?.forEach((key) => {
    if (key in object) {
      delete object[key];
    }
  });

  return object;
};
