import type { TurboPlatform } from '@canalplus/sdk-core';
import type { ApiV2TvodTracking } from '@dce-front/hodor-types/api/v2/common/dto/tvod/defintions';
import type { ApiV2TvodPaymentState } from '@dce-front/hodor-types/api/v2/tvod/payment/definitions';
import type { ApiV2PaymentMeansPurchase } from '@dce-front/hodor-types/api/v2/tvod/payment_means/definitions';
import {
  getQsValueByKey,
  removePropertiesFromObject,
} from '@dce-front/onewebapp-utils';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { paySaleOrder } from '../../../../../api/SaleApi/SaleApi';
import { QueryKeys } from '../../../../../constants/queryKeys';
import {
  PAY_SALE_ORDER_PROVIDER_ID,
  PAY_SALE_ORDER_SUCCEEDED_RETURN_CODE,
} from '../../../../../constants/sale';
import { isTim } from '../../../../../helpers/application/application-helper';
import { getPublicConfig } from '../../../../../helpers/config/config-helper';
import { useAppHistory } from '../../../../../helpers/hooks/reactRouter';
import { useAppDispatch } from '../../../../../helpers/hooks/useAppDispatch';
import {
  overlayTrackingName,
  sendPayVod,
} from '../../../../../helpers/tracking/tracking-helper';
import { isImmersiveSelector } from '../../../../../store/slices/immersive-selectors';
import type { PurchaseCodeState } from '../../../../../store/slices/purchaseCode';
import { updatePurchaseInfo } from '../../../../../store/slices/purchaseCode';
import { effectiveTrackingContextSelector } from '../../../../../store/slices/tracking-selectors';
import {
  QS_PROCESS_STATE,
  TVOD_ERROR,
  TVOD_SUCCESS,
} from '../../../../../templates/FunnelTvod/helpers/const';
import { FunnelHodorStep } from '../../../../../templates/FunnelTvod/stores/constants';
import {
  useFunnelDispatch,
  useFunnelPaymentMean,
} from '../../../../../templates/FunnelTvod/stores/funnel/hooks';
import type { PaymentMean } from '../../../../../templates/FunnelTvod/stores/types';
import {
  setCurrentStep,
  setIsLoading,
  setIsOpen as setIsOpenAction,
} from '../../../stores/funnel/actions';

/**
 * Kiss create order
 * ! Function use for not URBA backend
 * ! It should be deleted as soon as backend migrate to Turbo
 * @param dispatch redux dispatch
 * @param paymentMean kiss paymentMeans type
 * @param purchaseId order id
 * @param validationToken token use by ACM to verify purchase code
 * @returns returnCode api
 */
export const kissCreateOrder = async (
  dispatch: Redux.Dispatch,
  paymentMean: PaymentMean,
  purchaseId: string,
  validationToken?: string,
): Promise<string | undefined> => {
  const { contractId, contractPaymentMeanId } = paymentMean || {};

  const paySaleOrderOptions = {
    purchaseId,
    providerId: PAY_SALE_ORDER_PROVIDER_ID,
    ...(contractId !== undefined &&
      contractPaymentMeanId !== undefined && {
        purchasePayment: {
          contractPayment: { contractId, contractPaymentMeanId },
        },
      }),
  };

  const { returnCode } =
    (await dispatch(
      paySaleOrder({ data: paySaleOrderOptions, purchaseId, validationToken }),
    )) || {};
  return returnCode;
};

/**
 * Turbo create order
 *
 * @param dispatch redux dispatch
 * @param paymentMean turbo paymentMeans type
 * @param purchaseId order id
 * @param turboMedia media send by hodor template
 * @param validationToken token use by ACM to verify purchase code
 * @returns returnCode api
 */
export const turboCreateOrder = async (
  dispatch: Redux.Dispatch,
  paymentMean: PaymentMean,
  purchaseId: string,
  turboMedia: TurboPlatform,
  validationToken?: string,
): Promise<string | undefined> => {
  const paySaleOrderOptions = removePropertiesFromObject(
    [
      'paymentMeanInfo',
      'paymentMeanLabel',
      'pspLabel',
      'pspUrl',
      'reachedPurchaseLimit',
    ],
    JSON.parse(JSON.stringify(paymentMean)),
  );
  const { returnCode } =
    (await dispatch(
      paySaleOrder({
        data: paySaleOrderOptions,
        purchaseId,
        validationToken,
        turboMedia,
      }),
    )) || {};
  return returnCode;
};

type CreateOrderParams = {
  /** purchase id order */
  purchaseId: string;
  /** state for redirect urlPage success / error */
  currentContent?: ApiV2TvodPaymentState[];
  /** media send by hodor template */
  turboMedia?: TurboPlatform;
  /** acm check response validation token */
  validationToken?: string;
  /** tracking object hodor */
  tracking?: ApiV2TvodTracking;
  /** Object send to confirmation status */
  purchaseInfo?: PurchaseCodeState['purchaseInfo'];
};

/**
 * Hook that returns a callback capable of creating an order for a given purchase (turbo or kiss)
 * @param purchaseId purchase id
 * @param turboMedia media send by hodor template
 * @param validationToken token use by ACM to verify purchase code
 * @returns function capable of creating an order
 */
export const useCreateOrder = (): (({
  purchaseId,
  currentContent,
  turboMedia,
  validationToken,
  tracking,
  purchaseInfo,
}: CreateOrderParams) => Promise<void>) => {
  const dispatch = useAppDispatch();
  const funnelDispatch = useFunnelDispatch();
  const queryClient = useQueryClient();
  const paymentMean = useFunnelPaymentMean();
  const history = useAppHistory();
  const isImmersive = useSelector(isImmersiveSelector);
  const trackingContext = useSelector(effectiveTrackingContextSelector);

  return useCallback(
    async ({
      purchaseId,
      currentContent,
      turboMedia,
      validationToken,
      tracking,
      purchaseInfo,
    }: CreateOrderParams) => {
      dispatch(updatePurchaseInfo(purchaseInfo));
      // Display the spinner while creating and validating the order
      funnelDispatch(setIsLoading(true));
      const returnCode = isTim()
        ? turboMedia &&
          (await turboCreateOrder(
            dispatch,
            paymentMean,
            purchaseId,
            turboMedia || 'web',
            validationToken,
          ))
        : await kissCreateOrder(
            dispatch,
            paymentMean,
            purchaseId,
            validationToken,
          );
      const hasPaySaleOrderSucceed =
        returnCode === PAY_SALE_ORDER_SUCCEEDED_RETURN_CODE;

      /**
       * Refetch detail
       * DetailPage : Refetch detail
       * DetailActionLayout : Refetch perso to have Play button
       * DetailEpisodes : Refetch episode list for series
       *
       * !MYVAL-9177 should be fixed
       */
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.DetailActionLayout],
      });
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.DetailEpisodes],
      });

      // Default modal is on error
      const paymentState = !hasPaySaleOrderSucceed ? TVOD_ERROR : TVOD_SUCCESS;

      /**
       * If Hodor didn't provide currentContent close modal after processPayment
       * No modal is displayed but it is redirect to detail
       */
      if (!currentContent) {
        funnelDispatch(setIsOpenAction(false));
        return;
      }

      /**
       * We perform a wsFromPath in useGetPageContent depending of pathForWsFromPath
       * QS_PROCESS_STATE allow the new response from detail call with callbackMessage to display modal success or error
       */

      const paymentCallbackState = currentContent.find(
        (content: ApiV2TvodPaymentState) => content.id === paymentState,
      )?.callback;

      if (paymentCallbackState && paymentCallbackState.URLPage) {
        const currentContentRedirect = {
          ...paymentCallbackState,
          URLPage: paymentCallbackState.URLPage,
          path: `${paymentCallbackState.path}?${QS_PROCESS_STATE}=${getQsValueByKey(
            paymentCallbackState.URLPage,
            QS_PROCESS_STATE,
          )}`,
        };
        /**
         * Send tracking event on CTA on succeed
         */
        if (hasPaySaleOrderSucceed) {
          const payVodTrackingDataLayer = {
            ...tracking,
            dataLayer: {
              ...tracking?.dataLayer,
              ...trackingContext,
              event_name: 'pay vod',
              payment_method: paymentMean.paymentMeanLabel,
              page_level_3: 'Confirmation',
              page_name: `${overlayTrackingName[getPublicConfig().overlay]} - VOD transaction - Payment - Confirmation`,
            },
          };
          sendPayVod(payVodTrackingDataLayer);
        }
        /**
         * Change history to redirect to Detail Page
         * Immersive : Replace
         * SSR       : Push
         */
        if (isImmersive) {
          history.replace(currentContentRedirect.path, {
            ...history.location?.state,
            immersive: {
              ...history?.location?.state?.immersive,
              mainOnClick: currentContentRedirect,
            },
          });
        } else {
          history.push(currentContentRedirect.path, {
            ...history.location?.state,
            page: {
              ...history?.location?.state?.page,
              mainOnClick: currentContentRedirect,
            },
          });
        }
      }

      // Close funnel modal
      funnelDispatch(setIsOpenAction(false));
    },
    [
      dispatch,
      funnelDispatch,
      history,
      isImmersive,
      paymentMean,
      queryClient,
      trackingContext,
    ],
  );
};

type ProcessPaymentParams = {
  /** purchase data use for status */
  purchase: ApiV2PaymentMeansPurchase;
  /** state for redirect urlPage success / error */
  currentContent?: ApiV2TvodPaymentState[];
  /** media send by hodor template */
  turboMedia?: TurboPlatform;
  /** acm check response validation token */
  validationToken?: string;
  /** tracking object hodor */
  tracking?: ApiV2TvodTracking;
  /** Object send to confirmation status */
  purchaseInfo?: PurchaseCodeState['purchaseInfo'];
};

/**
 * Hook that handles process payment, by creating an order
 * @param purchase purchase object
 * @param turboMedia media send by hodor template
 * @param validationToken token use by ACM to verify purchase code
 * @returns function capable of creating an order
 */
export const useProcessPayment = (): (({
  purchase,
  currentContent,
  turboMedia,
  validationToken,
  tracking,
  purchaseInfo,
}: ProcessPaymentParams) => Promise<void>) => {
  const handleCreateOrder = useCreateOrder();
  const funnelDispatch = useFunnelDispatch();

  return useCallback(
    async ({
      purchase,
      currentContent,
      turboMedia,
      validationToken,
      tracking,
      purchaseInfo,
    }: ProcessPaymentParams) => {
      try {
        if (purchase && purchase.purchaseId) {
          await handleCreateOrder({
            purchaseId: purchase.purchaseId,
            currentContent,
            turboMedia,
            validationToken,
            tracking,
            purchaseInfo,
          });
        }
      } catch (e) {
        funnelDispatch(setIsLoading(false));
        funnelDispatch(
          setCurrentStep({ hodorStep: FunnelHodorStep.PaymentError }),
        );
        throw new Error(
          `[TVOD-URBA][useProcessPayment] Error in process payment ${e}`,
        );
      }
    },
    [handleCreateOrder, funnelDispatch],
  );
};
