import { removeQueriesFromUrl } from '@dce-front/onewebapp-utils';
import type { FunnelState } from '../types';
import type { FunnelAction } from './actions';
import { ActionType } from './actions';

const funnelReducer = (
  state: FunnelState,
  action: FunnelAction,
): FunnelState => {
  switch (action.type) {
    case ActionType.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload.currentStep,
      };
    case ActionType.SET_PREVIOUS_PAGE:
      return {
        ...state,
        previousPage: action.payload,
      };
    case ActionType.INCREMENT_FUNNEL_HISTORY: {
      const { funnelHistory } = action.payload;
      return {
        ...state,
        funnelHistory: state.funnelHistory
          ? [...state.funnelHistory, funnelHistory]
          : [funnelHistory],
      };
    }
    case ActionType.DECREMENT_FUNNEL_HISTORY: {
      const { funnelHistory } = state;
      // Decrement payment to contextual
      if (funnelHistory && funnelHistory[funnelHistory.length - 2]) {
        const newFunnelHistory = [...funnelHistory];
        const removeFromUrlVoucher = removeQueriesFromUrl(
          newFunnelHistory[newFunnelHistory.length - 2]?.url || '',
          ['addVoucher'],
        );
        newFunnelHistory.splice(newFunnelHistory.length - 2, 1, {
          ...newFunnelHistory[newFunnelHistory.length - 2],
          url: removeFromUrlVoucher,
        });
        return {
          ...state,
          currentStep: {
            ...newFunnelHistory[newFunnelHistory.length - 2],
            url: removeFromUrlVoucher,
          },
          funnelHistory: newFunnelHistory.slice(0, newFunnelHistory.length - 1),
        };
      } else {
        // default decrement case pop history
        return {
          ...state,
          ...(funnelHistory && {
            funnelHistory: funnelHistory.slice(0, funnelHistory.length - 1),
          }),
        };
      }
    }
    case ActionType.AMEND_FUNNEL_HISTORY: {
      const { funnelHistory = [] } = state;
      const amendedFunnelHistory = funnelHistory.slice(
        0,
        funnelHistory.length - 1,
      );
      amendedFunnelHistory.push(action.payload.newLastStep);
      return {
        ...state,
        funnelHistory: amendedFunnelHistory,
      };
    }
    case ActionType.ADD_PAYMENT_MEAN:
      return {
        ...state,
        paymentMean: action.payload.paymentMean,
      };
    case ActionType.RESET_PAYMENT_MEAN:
      return {
        ...state,
        paymentMean: {},
      };
    case ActionType.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ActionType.SET_OPEN:
      return {
        ...state,
        isOpen: action.payload,
      };
    case ActionType.SET_ERROR_TEMPLATE:
      return {
        ...state,
        errorTemplate: action.payload,
      };
    default:
      return state;
  }
};

export default funnelReducer;
